@tailwind base;
@tailwind components;
@tailwind utilities;
html {
    scroll-behavior: smooth;
}

body {
    margin: 1.563rem;
}

.main-background {
    background: #ECF0F3;
}

.active {
    color: #6a67ce;
}

a.cards-styles:hover,
a.cards-styles:active {
    background: #6a67ce;
    fill: white;
    transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
    transform: translateY(-.5rem);
}

.cards-feature:hover svg {
    fill: white;
}

.cards-feature:hover h4 {
    color: white;
}

.cards-feature:hover p {
    color: white;
}

.cards-portfolio:hover h4 {
    color: #6a67ce;
}

div.cards-feature:hover,
div.cards-feature:active {
    background: #6a67ce;
    fill: white;
    color: white;
    transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
    transform: translateY(-20px);
}

.gradient-background {
    background: linear-gradient(to right, #1CB5E0, #6a67ce);
    position: relative;
}

.cards-styles,
.cards-feature,
.cards-portfolio,
.cards-withouthover {
    background: linear-gradient(145deg, #e2e8ec, #ffffff);
}

.margin-cards {
    margin: 0.625rem 0.75rem;
}

.padding-features {
    padding: 2.188rem 3.125rem 2.188rem 3.125rem;
}

.text-center-webkit {
    text-align: -webkit-center;
}

.border-input {
    border: .125rem solid #dadada;
    padding: 0 0.9375rem;
}

.height-area {
    min-height: 14.6875rem;
}

.button-send {
    background: linear-gradient(145deg, #e2e8ec, #ffffff);
    box-shadow: .3125rem .3125rem .9375rem #D1D9E6, -.3125rem -.3125rem .9375rem #ffffff;
}

.button-send:hover {
    background: #6a67ce;
    color: white;
    box-shadow: .3125rem .3125rem .9375rem #D1D9E6, -.3125rem -.3125rem .9375rem #ffffff;
}

.resume-tabs {
    box-shadow: .3125rem .3125rem .9375rem #D1D9E6, -.3125rem -.3125rem .9375rem #ffffff;
    background: linear-gradient(145deg, #e2e8ec, #ffffff);
}

.space-tabs {
    padding: 1.875rem 0.625rem;
}

.resume-anchors:hover {
    background: #6a67ce;
    color: white;
}

.active-tabs {
    background: #6a67ce;
    color: white;
}

.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: .625rem .625rem 1.1875rem rgb(0 0 0 / 10%);
    z-index: 999;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0rem;
    padding-bottom: 0rem;
}

.apply-margin {
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.be-center {
    text-align: -webkit-center;
}

.toast-class {
    z-index: 1000;
    position: fixed;
}

.want-ul {
    list-style: inherit;
}